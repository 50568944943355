document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll("[data-toggle-password]").forEach((element) => {
    if (!(element instanceof HTMLElement))
      throw new Error("Element is not an HTML element");

    const TARGET_ID = element.dataset.targetId;

    if (!TARGET_ID) throw new Error("Element does not have a target ID");

    element.addEventListener("click", () => {
      element.classList.toggle("bi-eye-fill");
      element.classList.toggle("bi-eye-slash-fill");
      const password = document.getElementById(TARGET_ID) as HTMLInputElement;

      if (!password) throw new Error("Password element not found");

      password.type = password.type === "text" ? "password" : "text";
    });
  });
});
